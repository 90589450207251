<template>
  <div class="view-home">
      <div class="seminars-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><a href="/new-main">Главная</a></li>
              <li class="breadcrumbs-item"> Мероприятия</li>
              <li class="breadcrumbs-item"> Семинары, тренинги и повышение квалификации</li>
            </ul>
          </nav>
          <div class="seminars-title">
            Семинары, тренинги и повышение квалификации
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="seminars-text">
            Повышайте свою квалификацию с нашими семинарами и тренингами! Получите актуальные знания и практические
            навыки от лучших экспертов. Обменяйтесь опытом с коллегами и создайте полезные связи для успеха в вашей
            карьере. Независимо от вашего опыта — у нас есть программы, чтобы помочь вам стать лучше в своей области.
          </div>
        </div>
      </div>
    <div class="seminars-img-back">
      <div class="container">
        <div class="seminars-list-block">
          <div class="seminars-list-title">
            Республиканские семинары, тренинги и курсы повышение квалификации
          </div>
          <div class="seminars-list">
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="seminars-list-title accent-color">
            Международные семинары, тренинги и курсы повышение квалификации
          </div>
          <div class="seminars-list">
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
            <div class="seminars-list-item">
              <div class="seminars-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="seminars-list-item-details">
                <div class="seminars-list-item-date">
              <span>
                Дата проведения: 05.07.2024
              </span>
                  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1" height="24" fill="#D9D9D9"/>
                  </svg>
                  <span>
                Количество часов: 12 часов
              </span>
                </div>
                <div class="seminars-list-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности
                  школьников»
                </div>
                <div class="seminars-list-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности
                  детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических
                  инструментов,
                  связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области
                  будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="seminars-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="seminars-list-item-link">
                  Зарегистрироваться на семинар
                </div>
              </div>
            </div>
          </div>
          <nav class="seminars-pagination">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#">
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                        fill="black"/>
                  </svg>
                </a>
              </li>
              <li class="page-item"><a class="page-link active" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                        fill="black"/>
                  </svg>
                </a
                ></li>
            </ul>
          </nav>
        </div>
      </div>

      <el-dialog
          :visible.sync="dialogVisibleSeminars"
          :width="modalWidthSeminars"
      >
        <div class="seminars-modal-head">
          <img src="/images/modal-train-img.png" alt="" width="48" height="48">
          <div>
            <div class="seminars-modal-head-title">
              Регистрация на тренинг
            </div>
            <div class="seminars-modal-head-subtitle">
              Тренинг “Развитие эмоционального интеллекта в воспитании детей”
            </div>
          </div>
        </div>
        <div class="divide-line">
        </div>
        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              ФИО*
            </div>
            <span>
            (на русском)
          </span>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>
        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              ФИО*
            </div>
            <span>
            (на английском из паспорта)
          </span>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>

        <div class="divide-line">
        </div>

        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Область
            </div>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>
        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Город/район
            </div>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>

        <div class="divide-line">
        </div>

        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Наименование организации
            </div>
            <span>
            (место работы)*
          </span>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>
        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Адрес организации*
            </div>
          </div>
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Please input"
              v-model="textarea1">
          </el-input>
        </div>

        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Должность*
            </div>
          </div>
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Please input"
              v-model="textarea2">
          </el-input>
        </div>

        <div class="divide-line">
        </div>

        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              Контактный телефон *
            </div>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>
        <div class="seminars-modal-input">
          <div class="seminars-modal-label">
            <div>
              E-mail*
            </div>
          </div>
          <el-input placeholder="Please input" v-model="input"></el-input>
        </div>

        <div class="trainings-modal-btns">
          <button class="trainings-modal-cancel">
            Отменить
          </button>
          <button class="trainings-modal-request">
            Зарегистрироваться
          </button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  filters: {

  },
  data() {
    return {
      dialogVisibleSeminars: true,
      modalWidthSeminars: "688px",
      input: "",
      textarea1: "",
      textarea2: "",
    }
  }
}
</script>
<style>

</style>
